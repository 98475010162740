import React from "react";
import { rem } from "polished";
import { styled, useMediaValue } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { graphql } from "gatsby";
import { cx } from "@emotion/css";

type DownloadButtonProps = {
  className?: string;
  appInformation: GatsbyTypes.AppInformationType;
  locale: string;
};

const appStoreCodes = {
  en: "us",
  ko: "kr",
  ja: "jp",
  "zh-Hant": "tw",
  "zh-Hans": "cn",
  th: "th",
  de: "de",
  fr: "fr",
} as const;

const Container = styled.div((props) => ({
  textAlign: "center",
  boxSizing: "border-box",
  padding: `0 ${rem(5)}`,
  ['@media (min-width: 359px)']: {
    maxWidth: rem(350),
  },
  [props.theme.media["md"]]: {
    padding: `0 ${rem(10)}`,
  },
  ['@media (min-width: 640px)']: {
    maxWidth: rem(520),
  },
  ['@media (min-width: 995px)']: {
    maxWidth: 'max-content',
  },
}));

const StoreLink = styled.a((props) => ({
  margin: rem(2),
  display: 'inline-block',
  width: `calc(50% - ${rem(4)})`,
  height: 'auto',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
  borderRadius: rem(80),
  ['@media (min-width: 359px)']: {
    margin: rem(5),
    width: rem(156),
    height: rem(50),
  },
  [props.theme.media["md"]]: {
    width: rem(230),
    height: rem(64),
  },
}));

const StoreBanner = styled.img({
  width: '100%',
  height: '100%',
});

const getIsDesktopSize = (mediaValue: string) => mediaValue === 'md' || mediaValue === 'lg';

const DownloadButton: React.FC<DownloadButtonProps> = ({
  children,
  className,
  appInformation,
  locale,
}) => {
  const media = useMediaValue(['xs', 'sm', 'md', 'lg'] as const);
  const [imagePostFix, setImagePostFix] = React.useState('');

  React.useEffect(() => {
    setImagePostFix(getIsDesktopSize(media) ? '' : '-mobile');
  }, [media]);

  const { googleAppId, onestoreAppId, appleAppId, onelink } = appInformation;
  const userAgent =
    typeof window !== "undefined" ? window.navigator.userAgent : "";
  const [platform, setPlatform] = React.useState("unknown");

  React.useEffect(() => {
    if (userAgent.search(/(iPhone|iPad)/i) !== -1) {
      setPlatform("ios");
    } else if (userAgent.search(/Android/i) !== -1) {
      setPlatform("android");
    }
  }, [userAgent]);

  const isIos = platform === "ios";
  const isAndroid = platform === "android";
  const isMaybeDesktop = !isAndroid && !isIos;
  const appStoreTarget =
    appStoreCodes[locale as keyof typeof appStoreCodes] || "CA";

  return (
    <Container className={className}>
      {appleAppId && (isMaybeDesktop || isIos) && (
        <StoreLink
          target="_blank"
          rel="noopener"
          href={
            isMaybeDesktop
              ? `https://apps.apple.com/${appStoreTarget}/app/id${appleAppId}`
              : onelink
          }
        >
          <StoreBanner
            src={
              require(`!@svgr/webpack!file-loader!./downloadButton/appstore${imagePostFix}.svg`)
                .default
            }
            className={cx(isIos && "ios")}
          />
        </StoreLink>
      )}
      {googleAppId && (isMaybeDesktop || isAndroid) && (
        <>
          <StoreLink
            target="_blank"
            rel="noopener"
            href={
              isMaybeDesktop
                ? `https://play.google.com/store/apps/details?id=${googleAppId}`
                : onelink
            }
          >
            <StoreBanner
              src={
                require(`!@svgr/webpack!file-loader!./downloadButton/playstore${imagePostFix}.svg`)
                  .default
              }
            />
          </StoreLink>
          {onestoreAppId && (
            <StoreLink
              target="_blank"
              rel="noopener"
              href={`https://onesto.re/${onestoreAppId}`}
            >
              <StoreBanner
                src={
                  require(`!@svgr/webpack!file-loader!./downloadButton/onestore${imagePostFix}.svg`).default
                }
              />
            </StoreLink>
          )}
        </>
      )}
      {(isMaybeDesktop || isAndroid) && (
        <StoreLink
          target="_blank"
          rel="noopener"
          href={'https://galaxystore.samsung.com/detail/com.devsisters.ck'}
        >
          <StoreBanner
            src={
              require(`!file-loader!./downloadButton/galaxystore${imagePostFix}.svg`)
                .default
            }
          />
        </StoreLink>
      )}
    </Container>
  );
};

export default DownloadButton;

export const fragments = graphql`
  fragment DownloadButton_appInformation on SiteNavigation {
    appInformation {
      appleAppId
      googleAppId
      onestoreAppId
      onelink
    }
  }
`;
