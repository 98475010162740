import type { Media } from "~/src/media";
import type { LinkType } from "gatsby-theme-cookierun-kingdom/src/link";
import React from "react";
import Img from "gatsby-image";
import { rem } from "polished";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { goToTarget } from "gatsby-theme-cookierun-kingdom/src/browsing";
import { linkToId } from "gatsby-theme-cookierun-kingdom/src/link";

import KeyVisualBackground from "./keyVisual/KeyVisualBackground";
import ScrollButton from "./keyVisual/ScrollButton";
import PlayButton from "./keyVisual/PlayButton";
import DownloadButton from "./keyVisual/DownloadButton";

type KeyVisualProps = {
  desktopSourceSetList: Media[];
  mobileSourceSetList: Media[];
  gameContentsLink: LinkType;
  logoImage: GatsbyTypes.GlobalNavigation_logoImageFragment;
  appInformation: GatsbyTypes.AppInformationType;
  locale: string;
  trailerId: string;
  handlePlayVideo: (id: string) => void;
};

const Container = styled.div((props) => ({
  position: "relative",
  height: rem(490),
  [props.theme.media["sm"]]: {
    height: rem(684),
  },
  [props.theme.media["md"]]: {
    height: rem(926),
  },
  [props.theme.media["lg"]]: {
    minHeight: rem(926),
    height: "100vh",
  },
}));

const ContentWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  boxSizing: "border-box",
  overflow: "hidden",
});

const KeyVisualContentWrapper = styled.div({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const LogoWrapper = styled.div((props) => ({
  width: rem(220),
  height: rem(134),
  [props.theme.media["md"]]: {
    width: rem(370),
    height: rem(223),
  },
}));

const PlayButtonWrapper = styled.div((props) => ({
  width: rem(84),
  height: rem(84),
  margin: `${rem(20)} 0`,
  [props.theme.media["sm"]]: {
    margin: `${rem(48)} 0`,
    width: rem(124),
    height: rem(124),
  },
  [props.theme.media["md"]]: {
    margin: `${rem(40)} 0`,
    width: rem(162),
    height: rem(162),
  },
}));

const ScrollButtonWrapper = styled.div((props) => ({
  display: "none",
  marginBottom: rem(-20),
  [props.theme.media["lg"]]: {
    display: "block",
    marginTop: "auto",
  },
}));

const KeyVisual: React.FC<KeyVisualProps> = ({
  mobileSourceSetList,
  desktopSourceSetList,
  gameContentsLink,
  logoImage,
  locale,
  appInformation,
  trailerId,
  handlePlayVideo,
}) => {
  type GotoGameContentsHandler = React.MouseEventHandler<HTMLElement>;
  const handleGotoGameContents = React.useCallback<GotoGameContentsHandler>(
    (e) => {
      const targetId = linkToId(gameContentsLink);
      if (targetId) {
        goToTarget({
          targetId,
          beforeScroll: () => e.preventDefault(),
        });
      }
    },
    [gameContentsLink]
  );
  const onClickPlayButton = () => handlePlayVideo(trailerId);
  return (
    <Container>
      <KeyVisualBackground
        mobileSourceSetList={mobileSourceSetList}
        desktopSourceSetList={desktopSourceSetList}
      />
      <ContentWrapper>
        <KeyVisualContentWrapper>
          <LogoWrapper>
            {logoImage.fluid && <Img fluid={logoImage.fluid} loading="eager" />}
          </LogoWrapper>
          <PlayButtonWrapper>
            <PlayButton onClick={onClickPlayButton} />
          </PlayButtonWrapper>
          <DownloadButton appInformation={appInformation} locale={locale} />
        </KeyVisualContentWrapper>
        <ScrollButtonWrapper>
          <ScrollButton
            to={gameContentsLink}
            onClick={handleGotoGameContents}
          />
        </ScrollButtonWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default KeyVisual;
