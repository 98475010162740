import * as React from "react";
import type { LinkType } from "gatsby-theme-cookierun-kingdom/src/link";
import { rem } from "polished";
import { MainButtonLink } from "gatsby-theme-cookierun-kingdom/src/components/MainButton";
import SectionDeco from "gatsby-theme-cookierun-kingdom/src/components/SectionDeco";
import SectionTitle from "~/src/components/SectionTitle";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { useTranslation } from "~/src/components/l10nContext";

import { ReactComponent as PlayIconSvg } from "./mediaSection/playIcon.svg";

type MediaSectionProps = {
  mediaList: readonly GatsbyTypes.MediaSectionMediaItemFragment[];
  fallBackMediaList: readonly GatsbyTypes.FallBackMediaSectionMediaItemFragment[];
  youtubeLink: LinkType;
  handlePlayVideo: (id: string) => void;
};

const Container = styled.div((props) => ({
  backgroundColor: "#463E3A",
  width: "100%",
  textAlign: "center",
  height: "auto",
  paddingBottom: rem(40),
  [props.theme.media["md"]]: {
    paddingBottom: rem(80),
  },
}));

const SectionTitleWrapper = styled.div((props) => ({
  color: "#E0C595",
  padding: `${rem(33)} 0 ${rem(15)} 0`,
  [props.theme.media["md"]]: {
    padding: `${rem(66)} 0 ${rem(30)} 0`,
  },
}));

const MediaContentList = styled.ul((props) => ({
  width: "100%",
  margin: "0 auto",
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  gridTemplateColumns: "1fr",
  rowGap: rem(30),
  padding: `0 ${rem(40)}`,
  boxSizing: "border-box",
  listStyle: "none",
  [props.theme.media["md"]]: {
    padding: `0 ${rem(36)}`,
    columnGap: rem(15),
    rowGap: rem(33),
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [props.theme.media["lg"]]: {
    maxWidth: rem(1080),
    padding: 0,
    columnGap: rem(35),
    rowGap: rem(38),
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const MediaContentItem = styled.li({
  width: "100%",
  cursor: "pointer",
});

const MediaContentImageWrapper = styled.div({
  position: "relative",
  width: "100%",
  boxSizing: "border-box",
  backgroundColor: "#000",
  border: "1px solid #FFF3CD",
});

const MediaContentImage = styled.div({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  opacity: "0.7",
});

const PlayIcon = styled(PlayIconSvg)((props) => ({
  width: rem(28),
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: 1,
  [props.theme.media["sm"]]: {
    width: rem(40),
  },
}));

const MediaContentText = styled.p((props) => ({
  color: "#FFFFFF",
  textAlign: "left",
  margin: 0,
  fontSize: rem(18),
  lineHeight: 1.3,
  marginTop: rem(12),
  marginLeft: rem(6),
  textOverflow: "ellipsis",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  [props.theme.media["md"]]: {
    fontSize: rem(24),
    marginTop: rem(16),
    marginLeft: rem(8),
    height: rem(62),
  },
}));

const MainButtonLinkWrapper = styled.div((props) => ({
  marginTop: rem(40),
  [props.theme.media["md"]]: {
    marginTop: rem(60),
  },
}));

const MediaSection: React.FC<MediaSectionProps> = ({
  mediaList,
  fallBackMediaList,
  youtubeLink,
  handlePlayVideo,
}) => {
  const { t } = useTranslation();
  const mediaListLength = mediaList.length;
  return (
    <Container>
      <SectionDeco decoColor={"#C08D55"} />
      <SectionTitleWrapper>
        <SectionTitle
          mainTitle={t("GameIntroduction_Media_title")}
          preTitle={t("IndexPage_Section_subtitle")}
        />
      </SectionTitleWrapper>
      <MediaContentList>
        {mediaList.slice(0, 6).map((media, i) => (
          <MediaContentItem
            key={`media-content-${i}`}
            onClick={() => media.videoId && handlePlayVideo(media.videoId)}
          >
            <MediaContentImageWrapper>
              <PlayIcon />
              <MediaContentImage>
                {media.imageFile?.childImageSharp?.fluid && (
                  <Img
                    style={{ margin: "-10% 0" }}
                    fluid={media.imageFile.childImageSharp.fluid}
                    loading="auto"
                    alt={media.title}
                  />
                )}
              </MediaContentImage>
            </MediaContentImageWrapper>
            <MediaContentText>{media.title}</MediaContentText>
          </MediaContentItem>
        ))}
        {mediaListLength < 6 &&
          fallBackMediaList.slice(0, 6 - mediaListLength).map((media, i) => (
            <MediaContentItem
              key={`fallback-media-content-${i}`}
              onClick={() => media.videoId && handlePlayVideo(media.videoId)}
            >
              <MediaContentImageWrapper>
                <PlayIcon />
                <MediaContentImage>
                  {media.image.fluid && (
                    <Img
                      fluid={media.image.fluid}
                      loading="lazy"
                      alt={media.title}
                    />
                  )}
                </MediaContentImage>
              </MediaContentImageWrapper>
              <MediaContentText>{media.title}</MediaContentText>
            </MediaContentItem>
          ))}
      </MediaContentList>
      <MainButtonLinkWrapper>
        <MainButtonLink link={youtubeLink}>
          {t("GameIntroduction_Media_more_button")}
        </MainButtonLink>
      </MainButtonLinkWrapper>
    </Container>
  );
};

export default MediaSection;

export const fragments = graphql`
  fragment FallBackMediaSectionMediaItem on MediaItem {
    title
    videoId
    image {
      fluid(maxWidth: 560, srcSetBreakpoints: [320, 640, 1200], quality: 85) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment MediaSectionMediaItem on StrapiCkMediaContentsContentsYoutube_videos {
    title
    videoId
    imageFile {
      childImageSharp {
        fluid(maxWidth: 560, srcSetBreakpoints: [320, 640, 1200], quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
