import type { PageProps } from "gatsby";
import * as React from "react";
import { graphql } from "gatsby";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import Footer from "gatsby-theme-cookierun-kingdom/src/components/Footer";
import SiteNavigation from "gatsby-theme-cookierun-kingdom/src/components/SiteNavigation";
import GlobalNavigation from "gatsby-theme-cookierun-kingdom/src/components/GlobalNavigation";
import GameNavigation from "gatsby-theme-cookierun-kingdom/src/components/GameNavigation";
import MobileGameNavigation from "gatsby-theme-cookierun-kingdom/src/components/MobileGameNavigation";
import { linkToId } from "gatsby-theme-cookierun-kingdom/src/link";
import { useLink, useLinkUtils } from "~/src/siteMetadata";
import { useTranslation } from "~/src/components/l10nContext";
import NoticePopup from "~/src/components/NoticePopup";
import VideoPlayer from "~/src/components/VideoPlayer";
import Modal from "~/src/components/Modal";

import KeyVisual from "./KeyVisual";
import GameContents from "./GameContents";
import CharacterSection from "./CharacterSection";
import GameStorySection from "./GameStorySection";
import MediaSection from "./MediaSection";

import desktopKeyVisualH264VideoUrl from "~/src/images/pc.h264.mp4";
import desktopKeyVisualVP9VideoUrl from "~/src/images/pc.vp9.webm";
import mobileKeyVisualH264VideoUrl from "~/src/images/mobile.h264.mp4";
import mobileKeyVisualVP9VideoUrl from "~/src/images/mobile.vp9.webm";
import ageNoticeImageUrl from "~/src/images/ageNoticeImage.png";

type LocalizedIndexPageProps = PageProps<
  GatsbyTypes.LocalizedIndexPageQuery,
  GatsbyTypes.SitePageContext
>;

const SectionContainer = styled("div")({
  scrollBehavior: "smooth",
  position: "relative",
  zIndex: 0,
});

const Section = styled.section((props) => ({
  scrollMarginTop: 0,
  [props.theme.media["lg"]]: {
    scrollMarginTop: props.theme.navigationHeight,
  },
}));

const LocalizedIndexPage: React.FC<LocalizedIndexPageProps> = ({
  data,
  location,
  pageContext,
}) => {
  const logoImage = data.logoFile?.image;
  if (!logoImage?.fluid) {
    throw new Error("logo image should be exist");
  }

  const flatLogoImage = data.flatLogoFile?.image;
  if (!flatLogoImage?.fixed) {
    throw new Error("flat-logo image should be exist");
  }

  const gameStoryBackgroundImage = data.gameStoryBackgroundFile?.image;
  if (!gameStoryBackgroundImage?.fluid) {
    throw new Error("gameStoryBackgroundImage image should be exist");
  }

  const yellowCookieImage = data.yellowCookieFile?.image;
  if (!yellowCookieImage?.fluid) {
    throw new Error("yellowCookie image should be exist");
  }

  const purpleCookieImage = data.purpleCookieFile?.image;
  if (!purpleCookieImage?.fluid) {
    throw new Error("purpleCookie image should be exist");
  }

  const pinkCookieImage = data.pinkCookieFile?.image;
  if (!pinkCookieImage?.fluid) {
    throw new Error("pinkCookie image should be exist");
  }

  const whiteCookieImage = data.whiteCookieFile?.image;
  if (!whiteCookieImage?.fluid) {
    throw new Error("whiteCookie image should be exist");
  }

  const orangeCookieImage = data.oragneCookieFile?.image;
  if (!orangeCookieImage?.fluid) {
    throw new Error("oragneCookie image should be exist");
  }

  const frontCloudImage = data.frontCloudeFile?.image;
  if (!frontCloudImage?.fluid) {
    throw new Error("frontCloudImage image should be exist");
  }

  const backCloudImage = data.backCloudeFile?.image;
  if (!backCloudImage?.fluid) {
    throw new Error("backCloudImage image should be exist");
  }

  if (!data.eventTarget?.gameInformationList) {
    throw new Error(
      "LocalizedIndexPage : Can not found data.eventTarget.gameInformationList..."
    );
  }
  const characterPortraitCardImage = data.characterPortraitCardFile?.image;
  if (!characterPortraitCardImage?.fluid) {
    throw new Error("characterPortraitCardImage image should be exist");
  }

  if (!data.eventTarget?.characterInformationList) {
    throw new Error(
      "LocalizedIndexPage : Can not found data.eventTarget.characterInformationList..."
    );
  }

  if (!data.eventTarget?.language) {
    throw new Error(
      "LocalizedIndexPage : Can not found data.eventTarget.language..."
    );
  }

  if (!data.eventTarget) {
    throw new Error("그럴일 없음 ㅇㅇ");
  }

  if (!pageContext.eventTargetId) {
    throw new Error("이벤트 타겟 아이디가 없음.");
  }

  const { t } = useTranslation();
  const { parseLink } = useLinkUtils();
  const useHashLink = (hash: string) => useLink(location.pathname + hash);
  const homeLink = useHashLink("#top");
  const storyLink = useHashLink("#story");
  const gameContentsLink = useHashLink("#contents");
  const characterLink = useHashLink("#character");
  const mediaLink = useHashLink("#media");
  const companyInfo = data.siteNavigation?.company;
  const entries = data.siteNavigation?.entries?.map((entry) => ({
    ...entry,
    link: parseLink(entry.url),
  }));
  const socials = data.siteNavigation?.socials
    .map((social) => ({
      ...social,
      entry: {
        label: social.entry.label,
        link: parseLink(social.entry.url),
      },
    }))
    .filter(
      (social) =>
        pageContext.eventTargetId !== "ck-kr" || social.service !== "INSTAGRAM"
    );
  const terms = data.siteNavigation?.terms;
  const privacy = data.siteNavigation?.privacy;
  const cs = data.siteNavigation?.cs;
  const socialType = data.siteNavigation?.socialType;
  const trailerId = data.siteNavigation?.trailerId;
  const appInformation = data.siteNavigation?.appInformation;
  if (!entries) {
    throw new Error("entries data should be exist");
  }
  if (!socials) {
    throw new Error("socials data should be exist");
  }
  if (!companyInfo) {
    throw new Error("companyInfo data should be exist");
  }
  if (!terms) {
    throw new Error("terms link should be exist");
  }
  if (!privacy) {
    throw new Error("privacy link should be exist");
  }
  if (!cs) {
    throw new Error("cs link should be exist");
  }
  if (!socialType) {
    throw new Error("socialType shoud be exist");
  }
  if (!trailerId) {
    throw new Error("trailerId shoud be exist");
  }
  if (!appInformation) {
    throw new Error("appInformation shoud be exist");
  }
  const siteNavigationLink = socials.filter(
    (social) =>
      social.service ===
      ((socialType as unknown) as Partial<GatsbyTypes.SiteNavigationSocialService>)
  )[0].entry.link;
  const youtubeLink = socials.filter(
    (social) => social.service === "YOUTUBE"
  )[0].entry.link;
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = React.useState(false);
  const [isNoticePopupOpen, setIsNoticePopupOpen] = React.useState(true);
  const [iframeVideoLink, setIframeVideoLink] = React.useState("");
  const handlePlayVideo = (videoId: string) => {
    setIsVideoPlayerOpen(true);
    setIframeVideoLink(`https://www.youtube.com/embed/${videoId}`);
  };
  const handleClose = () => {
    setIsVideoPlayerOpen(false);
    setIsNoticePopupOpen(false);
  };
  return (
    <>
      <Modal
        isVideoPlayerOpen={isVideoPlayerOpen}
        isNoticePopupOpen={isNoticePopupOpen}
        handleClose={handleClose}
      >
        {isNoticePopupOpen && (
          <NoticePopup setIsNoticePopupOpen={setIsNoticePopupOpen} />
        )}
        {isVideoPlayerOpen && <VideoPlayer iframeVideoLink={iframeVideoLink} />}
      </Modal>
      <GameNavigation
        csLabel={t("Footer_cs")}
        csLink={parseLink(cs)}
        currentLocale={data.eventTarget.language}
        otherGames={data.eventTarget.otherGameList}
        upcomingLabel={t(`GameNavigation_Label_upcoming`)}
        preLabel={t(`GameNavigation_Label_preRegistration`)}
      />
      <SiteNavigation
        socialType={socialType}
        link={siteNavigationLink}
        twitterButtonText={t("SNS_QuickLink_Button_Label_twitter")}
        facebookButtonText={t("SNS_QuickLink_Button_Label_facebook")}
        cafeButtonText={t("SNS_QuickLink_Button_Label_cafe")}
        currentLocale={data.eventTarget.language}
      />
      <GlobalNavigation
        logoImage={logoImage}
        flatLogoImage={flatLogoImage}
        homeLink={homeLink}
        entries={entries}
        socials={socials}
        currentLocale={data.eventTarget.language}
      />
      <MobileGameNavigation
        otherGames={data.eventTarget.otherGameList}
        upcomingLabel={t(`GameNavigation_Label_upcoming`)}
        preLabel={t(`GameNavigation_Label_preRegistration`)}
        currentLocale={data.eventTarget.language}
      />
      <SectionContainer>
        <Section id={linkToId(homeLink)}>
          <KeyVisual
            desktopSourceSetList={[
              {
                type: 'video/webm; codec="vp9',
                src: desktopKeyVisualVP9VideoUrl,
              },
              { type: "video/mp4", src: desktopKeyVisualH264VideoUrl },
            ]}
            mobileSourceSetList={[
              {
                type: 'video/webm; codec="vp9',
                src: mobileKeyVisualVP9VideoUrl,
              },
              { type: "video/mp4", src: mobileKeyVisualH264VideoUrl },
            ]}
            logoImage={logoImage}
            appInformation={appInformation}
            locale={data.eventTarget.language}
            handlePlayVideo={handlePlayVideo}
            gameContentsLink={gameContentsLink}
            trailerId={trailerId}
          />
        </Section>
        <Section id={linkToId(gameContentsLink)}>
          <GameContents
            gameInformationList={data.eventTarget.gameInformationList}
          />
        </Section>
        <Section id={linkToId(characterLink)}>
          <CharacterSection
            characterInformationList={data.eventTarget.characterInformationList}
            characterPortraitCardImage={characterPortraitCardImage}
            currentLocale={data.eventTarget.language}
          />
        </Section>
        <Section id={linkToId(storyLink)}>
          <GameStorySection
            sectionTitleMainTitle={t("IndexPage_GameStorySection_title")}
            sectionTitlePreTitle={t("IndexPage_Section_subtitle")}
            storyText={t("IndexPage_GameStorySection_storyText")}
            gameStorybackgroundImage={gameStoryBackgroundImage}
            yellowCookieImage={yellowCookieImage}
            purpleCookieImage={purpleCookieImage}
            pinkCookieImage={pinkCookieImage}
            whiteCookieImage={whiteCookieImage}
            orangeCookieImage={orangeCookieImage}
            frontCloudImage={frontCloudImage}
            backCloudImage={backCloudImage}
          />
        </Section>
        <Section id={linkToId(mediaLink)}>
          <MediaSection
            fallBackMediaList={data.eventTarget.mediaList}
            mediaList={
              (data.allStrapiCkMediaContents.nodes[0]?.contents
                ?.youtube_videos ||
                []) as readonly GatsbyTypes.MediaSectionMediaItemFragment[]
            }
            youtubeLink={youtubeLink}
            handlePlayVideo={handlePlayVideo}
          />
        </Section>
        <Footer
          companyInfo={{
            company: {
              tel: { label: t("Footer_tel"), value: companyInfo.tel },
              fax: { label: t("Footer_fax"), value: companyInfo.fax },
              address: { value: companyInfo.address },
            },
            cs: {
              label: t("Footer_cs"),
              link: parseLink(cs),
            },
            terms: {
              label: t("Footer_term"),
              link: parseLink(terms),
            },
            privacy: {
              label: t("Footer_privacy"),
              link: parseLink(privacy),
            },
            law: {
              label: '特定商取引法に基づく表記',
              link: parseLink('https://policy.devsisters.com/ja/pay'),
            },
          }}
          socials={socials}
          currentLocale={data.eventTarget.language}
          ageNoticeImageUrl={ageNoticeImageUrl}
        />
      </SectionContainer>
    </>
  );
};

export default LocalizedIndexPage;

export const query = graphql`
  query LocalizedIndexPage(
    $eventTargetId: String!
    $language: String!
    $deployEnv: String!
    $translationId: String!
    $logoFilePath: String!
    $flatLogoFilePath: String!
    $gameStoryBackgroundFilePath: String!
    $yellowCookiePath: String!
    $purpleCookiePath: String!
    $pinkCookiePath: String!
    $whiteCookiePath: String!
    $oragneCookiePath: String!
    $frontCloudPath: String!
    $backCloudPath: String!
    $characterPortraitCardPath: String!
  ) {
    ...TranslationMessages

    siteNavigation(language: { eq: $language }) {
      trailerId
      ...GlobalNavigation_navigation_entries
      ...DownloadButton_appInformation
      ...Footer_socialLinks
      ...Footer_companyInfo
      ...SiteNavigation_socialType
    }

    allStrapiCkMediaContents(
      filter: { language: { eq: $language }, environment: { eq: $deployEnv } }
    ) {
      nodes {
        contents {
          youtube_videos {
            ...MediaSectionMediaItem
          }
        }
      }
    }

    eventTarget(eventTargetId: { eq: $eventTargetId }) {
      language
      gameInformationList {
        ...GameContentsGameInformationItem
      }
      characterInformationList {
        ...CharacterSectionCharacterInformationItem
      }
      mediaList {
        ...FallBackMediaSectionMediaItem
      }
      otherGameList {
        ...DevsistersGamesGameItem
      }
    }

    flatLogoFile: file(relativePath: { eq: $flatLogoFilePath }) {
      image: childImageSharp {
        ...GlobalNavigation_flatLogoImage
      }
    }

    logoFile: file(relativePath: { eq: $logoFilePath }) {
      image: childImageSharp {
        ...GlobalNavigation_logoImage
      }
    }

    gameStoryBackgroundFile: file(
      relativePath: { eq: $gameStoryBackgroundFilePath }
    ) {
      image: childImageSharp {
        ...GameStorySection_gameStoryBackgroundImage
      }
    }

    yellowCookieFile: file(relativePath: { eq: $yellowCookiePath }) {
      image: childImageSharp {
        ...GameStorySection_yellowCookieImage
      }
    }

    purpleCookieFile: file(relativePath: { eq: $purpleCookiePath }) {
      image: childImageSharp {
        ...GameStorySection_purpleCookieImage
      }
    }

    pinkCookieFile: file(relativePath: { eq: $pinkCookiePath }) {
      image: childImageSharp {
        ...GameStorySection_pinkCookieImage
      }
    }

    whiteCookieFile: file(relativePath: { eq: $whiteCookiePath }) {
      image: childImageSharp {
        ...GameStorySection_whiteCookieImage
      }
    }

    oragneCookieFile: file(relativePath: { eq: $oragneCookiePath }) {
      image: childImageSharp {
        ...GameStorySection_orangeCookieImage
      }
    }

    frontCloudeFile: file(relativePath: { eq: $frontCloudPath }) {
      image: childImageSharp {
        ...GameStorySection_frontCloudImage
      }
    }

    backCloudeFile: file(relativePath: { eq: $backCloudPath }) {
      image: childImageSharp {
        ...GameStorySection_backCloudImage
      }
    }

    characterPortraitCardFile: file(
      relativePath: { eq: $characterPortraitCardPath }
    ) {
      image: childImageSharp {
        ...CharacterSection_characterPortraitCardImage
      }
    }
  }
`;
